import { Component,OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { StorageService } from '../_services/storage.service';
import {FormControl, FormGroup, FormBuilder, Validators} from "@angular/forms"
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent  implements OnInit {

  form=this.formBuilder.group({
      username: ['username123',Validators.required],
      password: ['',Validators.required]
  });
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];

  constructor(private authService: AuthService, private storageService: StorageService, private formBuilder: FormBuilder , private router :Router) { 
   
  }

  ngOnInit(): void {
    if (this.storageService.isLoggedIn()) {
      this.isLoggedIn = true;
      //this.roles = this.storageService.getUser().roles;
    }
  }

  login(){
    //localStorage.removeItem('JWT_Token');
    if(this.form.valid){

      const chosenName = this.form.get('username')?.value || '';
      const password = this.form.get('password')?.value|| '';

      let rest = this.authService.login(chosenName, password).subscribe({
        next: data => {
          
          if (data) {
            console.log("El inicio de sesión fue exitoso");
            this.storageService.saveUser(data);  
            this.isLoginFailed = false;
            this.isLoggedIn = true;
            this.roles = this.storageService.getUser().roles;
            this.form.reset();
            this.router.navigateByUrl('/home');
            // manejar el éxito del inicio de sesión
          } else {
            console.log("El inicio de sesión falló");
            // manejar el fallo del inicio de sesión
          }
         
        },
        error: err => {
          console.log("FALLO");
          console.log(err);
          this.errorMessage = err.error.message;
          this.isLoginFailed = true;
        }
      });
      //this.router.navigateByUrl('/home');
      

      //
    }else{
      this.form.markAllAsTouched();
      console.log("FORM::: INVALIDO")
      alert("DEBE INGRESAR LOS DATOS")
    }
  }
  onSubmit(username:string, password:string): void {
    //const { username, password } = this.form;

    let rest = this.authService.login(username, password).subscribe({
      next: data => {
        this.storageService.saveUser(data);

        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.roles = this.storageService.getUser().roles;
       
      },
      error: err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    });
    console.log("RESPUESTA::: ",rest)
  }

  reloadPage(): void {
    window.location.reload();
  }

}
