<div >
    <div class="row justify-content-center">
      <div class="col-md-4">
        <h1 class="text-center">Login</h1>
        <form 
        [formGroup]="form"
        (ngSubmit)="login()"
        
        >
          <div class="mb-3">
            <label for="username" class="form-label">Username</label>
            <input type="text" class="form-control" id="username" formControlName="username">
            <div
            *ngIf="this.form.controls.username.invalid && (this.form.controls.username.dirty || this.form.controls.username.touched)"
            class="text-danger"
            >
            <div *ngIf="this.form.controls.username.errors?.['required']">El nombre es requerido.</div>
            </div>

          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Password</label>
            <input type="password" class="form-control" id="password" formControlName="password">
            <div
            *ngIf="this.form.controls.password.invalid && (this.form.controls.password.dirty || this.form.controls.password.touched)"
            class="text-danger"
          >
            <div *ngIf="this.form.controls.password.errors?.['required']">La contraseña es requerida.</div>
          </div>
          </div>
          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </div>
    </div>
  </div>