<!-- modal-content.component.html -->
<div class="modal-header">
    <h4 class="modal-title">Cargar Invitaciones</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form (submit)="submit()">
      <div class="form-group">
        <label for="file">Archivo Excel</label>
        <input type="file" id="file" (change)="onFileChange($event)" accept=".xlsx, .xls">
      </div>
      <button type="submit" class="btn btn-primary">Cargar</button>
    </form>
  </div>