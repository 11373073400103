import { Component,OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { FormBuilder } from '@angular/forms';
import { InvitacionesService } from '../_services/invitaciones.service';
import { Respuesta } from '../models/respuesta.model';
import { Estadisticas } from '../models/estadisticas.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  estadisticas : Estadisticas = {} as Estadisticas;
  progresoEnvio:number=0;
  progresoRespuestaCeremonia:number=0;
  progresoRespuestaRecepcion:number=0;
  constructor(private authService: AuthService, private fb: FormBuilder,private invitacionesService :InvitacionesService
  ) {

  }
  logout() {
    this.authService.logout();
  }

  ngOnInit(): void {
    this.invitacionesService.getEstadisticas().subscribe((respuesta: Respuesta<Estadisticas>) => {
      if(respuesta.responseCode == "200"){
        this.estadisticas=respuesta.objetoRespuesta;
        this.progresoRespuestaCeremonia = ((this.estadisticas.vaCeremoniaHila + this.estadisticas.vaCeremoniaJo + this.estadisticas.noVaCeremoniaHila + this.estadisticas.noVaCeremoniaJo)*100)
                /(this.estadisticas.invitacionesEnviadasHila + this.estadisticas.invitacionesEnviadasJo + this.estadisticas.invitacionesPendientesHila + this.estadisticas.invitacionesPendientesJo) ;

        this.progresoRespuestaRecepcion = ((this.estadisticas.vaRecepcionHila+ this.estadisticas.vaRecepcionJo + this.estadisticas.noVaRecepcionHila + this.estadisticas.noVaRecepcionJo)*100)
                /(this.estadisticas.invitacionesEnviadasHila + this.estadisticas.invitacionesEnviadasJo + this.estadisticas.invitacionesPendientesHila + this.estadisticas.invitacionesPendientesJo) ;

        this.progresoEnvio = (this.estadisticas.invitacionesEnviadasHila + this.estadisticas.invitacionesEnviadasJo ) * 100 /
                              (this.estadisticas.invitacionesEnviadasHila + this.estadisticas.invitacionesEnviadasJo 
                            + this.estadisticas.invitacionesPendientesHila + this.estadisticas.invitacionesPendientesJo) 
                            
      }
      
    });
  }

  isLoggedIn() {
    return this.authService.isAuthenticated();
  }
  
}
