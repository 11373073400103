<!-- Bootstrap CSS -->
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css">

<!-- Bootstrap Icons CSS --> 
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css">

  
  <div class="row justify-content-center">
    <div class="container">
        <form >
        <table class="table">
            <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Nombre</th>
              <th scope="col">Apellidos</th>
              <th scope="col">Telefono</th>
              <th scope="col">Va ceremonia?</th>
              <th scope="col">Va recpción?</th>
              
              <th>
                

              </th>
            </tr>
          </thead>
    
          <tbody>
            <ng-container *ngFor="let invitado of invitados; let i = index">
              <tr
                     
                  >
                <td>{{ invitado.idInvitado }}</td>
                <td>{{ invitado.nombre }}</td>
                <td>{{ invitado.apellidos }}</td>
                <td>{{ invitado.telefono }}</td>
                <td>{{ invitado.vaCeremonia }}</td>
                <td>{{ invitado.vaRecepcion }}</td>
                
                <td>
                  
                </td>
              </tr>
          
                  
            </ng-container>
          </tbody>
          
          
        </table>
    </form>
    </div>
    
  </div>
