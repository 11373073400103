import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InvitacionesService } from '../_services/invitaciones.service';
import { Respuesta } from '../models/respuesta.model';
import { Invitacion } from '../models/invitacion.model';

@Component({
  selector: 'app-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.scss']
})
export class ModalContentComponent {
  file: File | null = null;


  constructor(public activeModal: NgbActiveModal, private invitacionesService :InvitacionesService) { }

  onFileChange(event: any) {
    this.file = event.target.files[0];
  }

  submit() {
    if (this.file) {
        this.invitacionesService.cargarExcelInvitaciones(this.file).subscribe((respuesta: Respuesta<Invitacion[]>) => {
          
        this.activeModal.close();
      });
    }
  }

}
