import { CanActivate, CanActivateFn,Router  } from '@angular/router';
import { AuthService } from './_services/auth.service';
import {Injectable, inject} from "@angular/core";

@Injectable()
export class AuthGuard implements CanActivate {
//  let isauthenticated = inject(AuthService).isAuthenticated()
constructor(private authService: AuthService, private router: Router) {}

canActivate(): boolean {
  if (this.authService.isAuthenticated()) {
    return true;
  } else {
    this.router.navigate(['/']);
    return false;
  }
}
/*
 let router = inject(Router)

     if (isauthenticated) {
      return true;
    } else {
      router.navigate(['/Login']);
      return false;
    }*/
};
