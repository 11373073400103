import { Component,OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { InvitacionesService } from '../_services/invitaciones.service';
import { Respuesta } from '../models/respuesta.model';
import { Invitacion } from '../models/invitacion.model';

@Component({
  selector: 'app-lista-invitaciones',
  templateUrl: './lista-invitaciones.component.html',
  styleUrls: ['./lista-invitaciones.component.scss']
})
export class ListaInvitacionesComponent {

  constructor(private fb: FormBuilder, private invitacionesService:InvitacionesService){
    invitacionesService.getAll().subscribe((respuesta: Respuesta<Invitacion[]>) => {
      if(respuesta.responseCode == "200"){
        console.log("invitaciones",respuesta.objetoRespuesta);
        this.invitaciones = respuesta.objetoRespuesta;       
      }
      
    });
  }
  invitacionSelected:Invitacion = {} as Invitacion;
  isEditing: boolean = false
  invitaciones : Invitacion[] = []	;
  formInvitacion = this.fb.group({
    codigo: ['', [Validators.required]],
    de: ['', [Validators.required]],
    recepcion: [0, [Validators.required]],
    invitacionEnviada: [0, [Validators.required]],
  });

  selectInvitacion(invitacion: Invitacion) {
    console.log("invitacion seleccionada",invitacion)
    if(Object.keys(this.invitacionSelected).length === 0) {
      this.invitacionSelected = invitacion;
      this.isEditing = true
  
      this.formInvitacion.patchValue({
        codigo: invitacion.codigo,
        de: invitacion.de,
        recepcion: invitacion.recepcion,
        invitacionEnviada: invitacion.invitacionEnviada
      })
    }
  }

  update() {
     
     if(!this.isEditing) {
       this.invitaciones[0] = {
       //  id: this.generateId(),
         codigo:this.formInvitacion.value.codigo!,
         de: this.formInvitacion.value.de!,
         recepcion: this.formInvitacion.value.recepcion!,
         invitacionEnviada: this.formInvitacion.value.invitacionEnviada!,
         cantidad:0,
         numero:'',
         invitados:[]
       }
       console.log("usuarios por crear",this.invitaciones[0]);
       this.invitacionesService.agregarInvitacion(this.invitaciones[0]).subscribe(
         (response) => {
           console.log('usuarios actualizado', response);
         },
         (error) => {
           console.log('Error al actualizar el usuario', error);
         }
       );
     }   
     else {
       let index = this.invitaciones.map(u => u.codigo).indexOf(this.invitacionSelected.codigo);
       console.log("Form de",this.formInvitacion.value.de);
       this.invitaciones[index] = {
         //id: this.userSelected.id,
         ...this.invitacionSelected,
         codigo: this.invitacionSelected.codigo,
         de: this.formInvitacion.value.de ?? '',
         recepcion: this.formInvitacion.value.recepcion?1:0,
         invitacionEnviada: this.formInvitacion.value.invitacionEnviada?1:0,
       };
       
       console.log("usuario actualizado",this.invitaciones[index]);
       this.invitacionesService.actualizarInvitacion(this.invitaciones[index]).subscribe(
         (response) => {
           console.log('usuario actualizado', response);
         },
         (error) => {
           console.log('Error al actualizar el usuario', error);
         }
       );
     }
   
     // clean up
     this.invitacionSelected = {} as Invitacion;
     this.isEditing = false 
     this.formInvitacion.reset();  
     
   }


   addInvitacion() {
    this.invitaciones.unshift({
      codigo: '',
      recepcion: 0,
      invitacionEnviada: 0,
      cantidad: 0,
      numero: '',
      de: '',
      invitados: []
      
    })
    this.invitacionSelected = this.invitaciones[0];
  }

  cancel() {
    if(!this.isEditing && confirm('All unsaved changes will be removed. Are you sure you want to cancel?')) {
          // removes the user that was added
      this.invitaciones.splice(0, 1);
    }
  
    this.invitacionSelected = {} as Invitacion;
    this.isEditing = false
    this.formInvitacion.reset();
  }


}
