<!-- Bootstrap CSS -->
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css">

<!-- Bootstrap Icons CSS --> 
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css">

  
  <div class="row justify-content-center">
    <div class="container">
        <form [formGroup]="formInvitacion" (ngSubmit)="update()">
        <table class="table">
            <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Recepcion?</th>
              <th scope="col">Enviada?</th>
              <th scope="col">De?</th>
              
              <th>
                <button
                    type="button"
                    title="Add New"
                    class="btn btn-primary"
                    (click)="addInvitacion()"
                    >
                    <i class="bi bi-plus"></i>
                </button>

                

              </th>
            </tr>
          </thead>
    
          <tbody>
            <ng-container *ngFor="let invitacion of invitaciones; let i = index">
              <tr
                      *ngIf="invitacionSelected != invitacion"
                      (click)="selectInvitacion(invitacion)"
                  >
                <td>{{ invitacion.codigo }}</td>
                <td [ngClass]="{ 'table-success': invitacion.recepcion, 'table-danger': !invitacion.recepcion }">{{ invitacion.recepcion ? 'Sí' : 'No' }}</td>
                <td [ngClass]="{ 'table-success': invitacion.invitacionEnviada, 'table-danger': !invitacion.invitacionEnviada }">{{ invitacion.invitacionEnviada ? 'Sí' : 'No' }}</td>
                <td>{{ invitacion.de == 'H' ? 'Hila' : (invitacion.de == 'J' ? 'Jo' : 'NA') }}</td>
                
                <td>
                  
                </td>
              </tr>
          
              <tr *ngIf="invitacionSelected == invitacion" class="table-primary">
                <td>
                  <input
                      type="text"
                      class="form-control"
                      formControlName="codigo"
                      [value]="invitacion.codigo"
                      title="codigo"
                      *ngIf="invitacionSelected.codigo=='' "
                      />
                      <div *ngIf="invitacionSelected.codigo!='' ">
                        {{ invitacion.codigo }}
                      </div>
                  
                </td>
                <td>
                    <div class="form-check form-switch">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            [checked]="invitacion.recepcion"
                            formControlName="recepcion"
                            [value]="invitacion.recepcion"
                            title="recepcion"
                        
                        />
                    </div>
                  </td>
                <td>
                    <div class="form-check form-switch">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            [checked]="invitacion.invitacionEnviada"
                            formControlName="invitacionEnviada"
                            [value]="invitacion.invitacionEnviada"
                            title="invitacionEnviada"
                            />
                    </div>
                </td>
                <td>
                  <div class="form-check form-switch">
                    <select class="form-select" formControlName="de" [value]="invitacion.de" title="de" >
                      <option value="H">Hila</option>
                      <option value="J">Jo</option>
                    </select>
                  </div>
                </td>
                <td>             
                  <button
                      class="btn btn-primary btn-sm mx-1"
                      title="update"
                      type="button"
                      (click)="update()"
                    >
                      <i class="bi bi-lightning"></i>
                    </button>
          
                    <button
                        class="btn btn-secondary btn-sm mx-1"
                        title="Cancel Changes"
                        type="button"
                            (click)="cancel()"
                        >
                            <i class="bi bi-x-lg"></i>
                        </button>
                        
                  </td>


                  <!-- Delete User button, app.component.html -->
                
              </tr>
            </ng-container>
          </tbody>
          
          
        </table>
    </form>
    </div>
    
  </div>
