<div class="container" *ngIf="isLoggedIn()">

  <h3>Estadisticas</h3>
  <div class="row">
    <table class="table">
      <thead>

        <tr>
          <th scope="col" class="w-25"></th>
          <th scope="col">Hila</th>
          <th scope="col">Jo</th>

          <th scope="col">Suma</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td scope="row" class="w-25"></td>
          <td colspan="3" class="table-active">Invitados</td>

        </tr>
        <tr>
          <td scope="row" class="w-25">Ceremonia</td>
          <td> <a href="javascript:void(0);"
              [routerLink]="['/estadisticas']"
              [queryParams]="{ de: 'h', tipo: 'invitados', filtro: 'ceremonia' }">
              {{estadisticas.invitadosCeremoniaHila}} </a></td>
          <td> <a href="javascript:void(0);"
              [routerLink]="['/estadisticas']"
              [queryParams]="{ de: 'j', tipo: 'invitados', filtro: 'ceremonia' }">{{estadisticas.invitadosCeremoniaJo}}
            </a></td>

          <td class="table-primary"> <a href="javascript:void(0);"
              [routerLink]="['/estadisticas']"
              [queryParams]="{ de: 'B', tipo: 'invitados', filtro: 'ceremonia' }">{{estadisticas.invitadosCeremoniaHila
              + estadisticas.invitadosCeremoniaJo}} </a></td>

        </tr>
        <tr>
          <td scope="row" class="w-25">Recepcion</td>
          <td> <a href="javascript:void(0);"
              [routerLink]="['/estadisticas']"
              [queryParams]="{ de: 'h', tipo: 'invitados', filtro: 'recepcion' }">{{estadisticas.invitadosRecepcionHila}}
            </a></td>
          <td> <a href="javascript:void(0);"
              [routerLink]="['/estadisticas']"
              [queryParams]="{ de: 'j', tipo: 'invitados', filtro: 'recepcion' }">{{estadisticas.invitadosRecepcionJo}}
            </a></td>

          <td class="table-primary"> <a href="javascript:void(0);"
              [routerLink]="['/estadisticas']"
              [queryParams]="{ de: 'B', tipo: 'invitados', filtro: 'recepcion' }">{{estadisticas.invitadosRecepcionHila
              + estadisticas.invitadosRecepcionJo}} </a></td>
        </tr>

        <tr>
          <td scope="row" class="w-25"></td>
          <td colspan="3" class="table-active">Invitaciones</td>
        </tr>
        <tr>
          <td scope="row" class="w-25">Enviadas</td>
          <td> {{estadisticas.invitacionesEnviadasHila}} </td>
          <td> {{estadisticas.invitacionesEnviadasJo}} </td>

          <td class="table-primary"> {{estadisticas.invitacionesEnviadasHila +
            estadisticas.invitacionesEnviadasJo}} </td>

        </tr>
        <tr>
          <td scope="row" class="w-25">Pendientes</td>
          <td> {{estadisticas.invitacionesPendientesHila}} </td>
          <td> {{estadisticas.invitacionesPendientesJo}} </td>

          <td class="table-primary"> {{estadisticas.invitacionesPendientesHila +
            estadisticas.invitacionesPendientesJo}} </td>
        </tr>
        <tr>
          <td scope="row" class="w-25 table-success">Totales</td>
          <td class="table-success"> {{estadisticas.invitacionesEnviadasHila +
            estadisticas.invitacionesPendientesHila}} </td>
          <td class="table-success"> {{estadisticas.invitacionesEnviadasJo +
            estadisticas.invitacionesPendientesJo}} </td>

          <td class=" table-primary"> {{ estadisticas.invitacionesEnviadasHila +
            estadisticas.invitacionesPendientesHila+
            estadisticas.invitacionesEnviadasJo +
            estadisticas.invitacionesPendientesJo}} </td>

        </tr>

        <tr>
          <td scope="row" class="w-25"></td>
          <td colspan="3" class="table-active">Confirmaciones</td>
        </tr>
        <tr>
          <td scope="row" class="w-25">Va ceremonia</td>
          <td class="table-success">
            <a href="javascript:void(0);"
              [routerLink]="['/estadisticas']"
              [queryParams]="{ de: 'h', tipo: 'confirmacion', filtro: 'vaceremonia' }"> {{estadisticas.vaCeremoniaHila }}
            </a>
          </td>
          <td class="table-success">
            <a href="javascript:void(0);"
              [routerLink]="['/estadisticas']"
              [queryParams]="{ de: 'j', tipo: 'confirmacion', filtro: 'vaceremonia' }">{{estadisticas.vaCeremoniaJo}}
            </a>
          </td>
          <td class="table-primary">
            <a href="javascript:void(0);"
              [routerLink]="['/estadisticas']"
              [queryParams]="{ de: 'B', tipo: 'confirmacion', filtro: 'vaceremonia' }">{{estadisticas.vaCeremoniaHila +
              estadisticas.vaCeremoniaJo}} </a>
          </td>

        </tr>
        <tr>
          <td scope="row" class="w-25">Va recepcion</td>
          <td class="table-success"> <a href="javascript:void(0);"
              [routerLink]="['/estadisticas']"
              [queryParams]="{ de: 'h', tipo: 'confirmacion', filtro: 'varecepcion' }">{{estadisticas.vaRecepcionHila
              }}</a></td>
          <td class="table-success"> <a href="javascript:void(0);"
              [routerLink]="['/estadisticas']"
              [queryParams]="{ de: 'j', tipo: 'confirmacion', filtro: 'varecepcion' }">{{estadisticas.vaRecepcionJo
              }}</a></td>

          <td class="table-primary"> <a href="javascript:void(0);"
              [routerLink]="['/estadisticas']"
              [queryParams]="{ de: 'B', tipo: 'confirmacion', filtro: 'varecepcion' }">{{estadisticas.vaRecepcionHila +
              estadisticas.vaRecepcionJo }}</a></td>
        </tr>

        <tr>
          <td scope="row" class="w-25">NO Va ceremonia</td>
          <td class="table-success"> <a href="javascript:void(0);"
              [routerLink]="['/estadisticas']"
              [queryParams]="{ de: 'h', tipo: 'confirmacion', filtro: 'novaceremonia' }">{{estadisticas.noVaCeremoniaHila
              }}</a></td>
          <td class="table-success"> <a href="javascript:void(0);"
              [routerLink]="['/estadisticas']"
              [queryParams]="{ de: 'j', tipo: 'confirmacion', filtro: 'novaceremonia' }">{{estadisticas.noVaCeremoniaJo
              }}</a></td>

          <td class="table-primary"> <a href="javascript:void(0);"
              [routerLink]="['/estadisticas']"
              [queryParams]="{ de: 'B', tipo: 'confirmacion', filtro: 'novaceremonia' }">{{estadisticas.noVaCeremoniaHila
              + estadisticas.noVaCeremoniaJo}}</a></td>

        </tr>
        <tr>
          <td scope="row" class="w-25">NO Va recepcion</td>
          <td class="table-success"> <a href="javascript:void(0);"
              [routerLink]="['/estadisticas']"
              [queryParams]="{ de: 'h', tipo: 'confirmacion', filtro: 'novarecepcion' }">{{estadisticas.noVaRecepcionHila
              }}</a></td>
          <td class="table-success"> <a href="javascript:void(0);"
              [routerLink]="['/estadisticas']"
              [queryParams]="{ de: 'j', tipo: 'confirmacion', filtro: 'novarecepcion' }">{{estadisticas.noVaRecepcionJo
              }}</a></td>

          <td class="table-primary"> <a href="javascript:void(0);"
              [routerLink]="['/estadisticas']"
              [queryParams]="{ de: 'B', tipo: 'confirmacion', filtro: 'novarecepcion' }">{{estadisticas.noVaRecepcionHila
              + estadisticas.noVaRecepcionJo}}</a></td>
        </tr>

        <tr>
          <td scope="row" class="w-25">NO ha confirmado ceremonia</td>
          <td class="table-success"> <a href="javascript:void(0);"
              [routerLink]="['/estadisticas']"
              [queryParams]="{ de: 'h', tipo: 'confirmacion', filtro: 'noconfirmaceremonia' }">{{estadisticas.noConfirmadoCeremoniaHila
              }}</a></td>
          <td class="table-success"> <a href="javascript:void(0);"
              [routerLink]="['/estadisticas']"
              [queryParams]="{ de: 'j', tipo: 'confirmacion', filtro: 'noconfirmaceremonia' }">{{estadisticas.noConfirmadoCeremoniaJo
              }}</a></td>

          <td class="table-primary"> <a href="javascript:void(0);"
              [routerLink]="['/estadisticas']"
              [queryParams]="{ de: 'B', tipo: 'confirmacion', filtro: 'noconfirmaceremonia' }">{{estadisticas.noConfirmadoCeremoniaHila
              + estadisticas.noConfirmadoCeremoniaJo}}</a></td>

        </tr>
        <tr>
          <td scope="row" class="w-25">NO ha confirmado recepcion</td>
          <td class="table-success"> <a href="javascript:void(0);"
              [routerLink]="['/estadisticas']"
              [queryParams]="{ de: 'h', tipo: 'confirmacion', filtro: 'noconfirmarecepcion' }">{{estadisticas.noConfirmadoRecepcionHila}}</a></td>
          <td class="table-success"> <a href="javascript:void(0);"
              [routerLink]="['/estadisticas']"
              [queryParams]="{ de: 'j', tipo: 'confirmacion', filtro: 'noconfirmarecepcion' }">{{estadisticas.noConfirmadoRecepcionJo}}</a></td>

          <td class="table-primary"> <a href="javascript:void(0);"
              [routerLink]="['/estadisticas']"
              [queryParams]="{ de: 'B', tipo: 'confirmacion', filtro: 'noconfirmarecepcion' }">{{estadisticas.noConfirmadoRecepcionHila
              + estadisticas.noConfirmadoRecepcionJo}}</a></td>
        </tr>

      </tbody>

    </table>
  </div>

  <div class="div">
    <div class="h3">Progreso de envío de invitaciones</div>
    <div class="progress">
      <div class="progress-bar" role="progressbar"
        [style.width.%]="progresoEnvio"
        [attr.aria-valuenow]="progresoEnvio"
        aria-valuemin="0"
        aria-valuemax="100">{{progresoEnvio | number:'1.1-1'}}</div>
    </div>
  </div>

  <div class="div">
    <div class="h3">Porcentaje respuesta ceremonia</div>
    <div class="progress">
      <div class="progress-bar" role="progressbar"
        [style.width.%]="progresoRespuestaCeremonia"
        [attr.aria-valuenow]="progresoRespuestaCeremonia"
        aria-valuemin="0"
        aria-valuemax="100">{{ progresoRespuestaCeremonia |
        number:'1.1-1'}}%</div>
    </div>
  </div>

  <div class="div">
    <div class="h3">Porcentaje respuesta recepcion</div>
    <div class="progress">
      <div class="progress-bar" role="progressbar"
        [style.width.%]="progresoRespuestaRecepcion"
        [attr.aria-valuenow]="progresoRespuestaRecepcion"
        aria-valuemin="0"
        aria-valuemax="100">{{ progresoRespuestaRecepcion |
        number:'1.1-1'}}%</div>
    </div>
  </div>
</div>