<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="javascript:void(0);">Invitaciones</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarFiltro">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Filtro recepcion
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><a class="dropdown-item" href="javascript:void(0);" (click)="filtroRecepcion('')">Todas</a></li>
            <li><a class="dropdown-item" href="javascript:void(0);" (click)="filtroRecepcion('S')">Si</a></li>
            <li><a class="dropdown-item" href="javascript:void(0);" (click)="filtroRecepcion('N')">No</a></li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="collapse navbar-collapse" id="navbarFiltro">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Filtro Enviadas
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><a class="dropdown-item" href="javascript:void(0);" (click)="filtroEnviadas('')">Todas</a></li>
            <li><a class="dropdown-item" href="javascript:void(0);" (click)="filtroEnviadas('S')">Si</a></li>
            <li><a class="dropdown-item" href="javascript:void(0);" (click)="filtroEnviadas('N')">No</a></li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="collapse navbar-collapse" id="navbarFiltro">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Pertenece?
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><a class="dropdown-item" href="javascript:void(0);" (click)="filtroDe('')">Todas</a></li>
            <li><a class="dropdown-item" href="javascript:void(0);" (click)="filtroDe('H')">Hila</a></li>
            <li><a class="dropdown-item" href="javascript:void(0);" (click)="filtroDe('J')">Jo</a></li>
            <li><a class="dropdown-item" href="javascript:void(0);" (click)="filtroDe('N')">NA</a></li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Opciones
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><a class="dropdown-item" href="javascript:void(0);" [routerLink]="['/listaInvitaciones']">Lista</a></li>
            <li><a class="dropdown-item" href="javascript:void(0);" (click)="cargarInvitaciones()">Cargar</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="row">
  
  
</div>
<div class="row">
  <div class="col-md-4" *ngFor="let grupo of grupoFiltrado; let z = index">
    <div class="accordion" id="accordionExample{{z}}" >
      <div
        class="accordion-item"
        *ngFor="let invitacion of grupo.Invitacion; let i = index"
      >
        <h2 class="accordion-header" id="heading{{ z }}{{ i }}">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            [attr.data-bs-target]="'#collapse'+z + i"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            <b>{{ invitacion.codigo }}</b> -
            <label *ngIf="invitacion.recepcion == 1">[Recepción]</label>
          </button>
        </h2>
        <div
          id="collapse{{ z }}{{ i }}"
          class="accordion-collapse collapse"
          aria-labelledby="headingOne"
          [attr.data-bs-parent]="'#accordionExample' + z"
        >
          <div class="accordion-body">
            <div class="row">
              <div
                class="card-header d-flex justify-content-between align-items-center"
              >
                <h3 class="mb-0">Invitados</h3>
                <button
                  class="btn btn-success"
                  (click)="openWhatsApp(invitacion.numero, invitacion.codigo)"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Enviar por WhatsApp"
                >
                  <i class="fas fa-paper-plane"></i>
                </button>
                <button
                  class="btn "
                  [ngClass]="{
                    'btn-primary': invitacion.invitacionEnviada === 0,
                    'btn-outline-primary': invitacion.invitacionEnviada === 1
                  }"
                  [disabled]="invitacion.invitacionEnviada === 1"
                  (click)="marcarEnviado(invitacion)"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Marcar como envíado"
                >
                  <i class="fa fa-check-circle"></i>
                </button>

                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="recepcionSwitch{{ invitacion.codigo }}"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Asiste a recepción"
                    (change)="actualizarrecepcion(invitacion, $event)"
                    [checked]="invitacion.recepcion === 1"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="alert alert-info" role="alert" *ngIf="invitacion.invitacionEnviada === 1">
                La invitación ya fue enviada
              </div>
            </div>

            <ul class="list-group list-group-flush mt-2 pt-2 border-top">
              <li
                class="list-group-item"
                *ngFor="let invitado of invitacion.invitados; let y = index"
              >
                <div class="row">
                  <div class="col-md-6">
                    {{ invitado.nombre }} {{ invitado.apellidos }}
                  </div>
                  <div class="col-md-2">
                    <button
                      class="btn btn-sm btn-outline-danger"
                      (click)="eliminar(invitacion.codigo, invitado)"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Eliminar"
                    >
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </li>
            </ul>

            <div class="card-body mt-2 pt-2 border-top">
              <div class="row">
                <div class="col-md-9">
                  <div class="form-group">
                    <ng-select
                      [items]="invitadosSinInvitacion"
                      bindLabel="nombreCompleto"
                      bindValue="id"
                      placeholder="Selecciona una opción"
                      class="custom"
                      [(ngModel)]="invitadoSeleccionado"
                      (change)="onSelectInvitado($event,z, i)"
                    >
                    </ng-select>
                  </div>
                </div>
                <div class="col-md-3 d-flex justify-content-end">
                  <button
                    class="btn btn-link card-link text-end"
                    (click)="agregarInvitado()"
                  >
                    Agregar
                  </button>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>  
</div>
<div class="row">
    <div class="alert alert-success d-flex align-items-center" role="alert" *ngIf="existeExito">
      <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg>
      <div>
        {{mensajeExito}}
      </div>
      <span class="btn-close" (click)="cerrarSuccess()" style="float: right"
        ></span>
    </div>

    <div class="alert alert-danger" *ngIf="existeError">
      {{ mensajeError }}
      <span class="btn-close" (click)="cerrarAlerta()" style="float: right"
        ></span>
    </div>
</div>
