<!-- Bootstrap CSS -->
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css">

<!-- Bootstrap Icons CSS --> 
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css">

  
  <div class="row justify-content-center">
    <div class="container">
        <form [formGroup]="formSolicitud" (ngSubmit)="update()">
        <table class="table">
            <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Nombre</th>
              <th scope="col">Telefono</th>
              <th scope="col">Cantidad</th>
              <th scope="col">Comentarios</th>
              <th>
                
                Resuelta?
                

              </th>
            </tr>
          </thead>
    
          <tbody>
            <ng-container *ngFor="let solicitud of solicitudes; let i = index">
              <tr
              *ngIf="solicitudSelected != solicitud"
              (click)="selectSolicitud(solicitud)"
                      
                  >
                <td>{{ solicitud.id }}</td>
                <td>{{ solicitud.nombre }}</td>
                <td>{{ solicitud.telefono }}</td>
                <td>{{ solicitud.cantidad }}</td>
                <td>{{ solicitud.comentarios }}</td>
                <td [ngClass]="{ 'table-success': solicitud.resuelta, 'table-danger': !solicitud.resuelta }">{{ solicitud.resuelta ? 'Sí' : 'No' }}</td>

                <td>
                  
                </td>
              </tr>

              <tr *ngIf="solicitudSelected == solicitud" class="table-primary">
                <td>{{ solicitud.id }}</td>
                <td>{{ solicitud.nombre }}</td>
                <td>{{ solicitud.telefono }}</td>
                <td>{{ solicitud.cantidad }}</td>
                <td>{{ solicitud.comentarios }}</td>

                <td>
                  <div class="form-check form-switch">
                      <input
                          class="form-check-input"
                          type="checkbox"
                          [checked]="solicitud.resuelta"
                          formControlName="resuelta"
                          [value]="solicitud.resuelta"
                          title="resuelta"
                      
                      />
                  </div>
                </td>

                <td>             
                  <button
                      class="btn btn-primary btn-sm mx-1"
                      title="update"
                      type="button"
                      (click)="update()"
                    >
                      <i class="bi bi-lightning"></i>
                    </button>
          
                    <button
                        class="btn btn-secondary btn-sm mx-1"
                        title="Cancel Changes"
                        type="button"
                            (click)="cancel()"
                        >
                            <i class="bi bi-x-lg"></i>
                        </button>
                        
                  </td>

              </tr>
          
                  
            </ng-container>
          </tbody>
          
          
        </table>
    </form>
    </div>
    
  </div>
