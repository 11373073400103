import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Respuesta } from '../models/respuesta.model';
import { Invitado } from '../models/invitado.model';
import { Parametros } from '../models/parametros.model';
import { InvitadoEstadistica } from '../models/invitadoEstadistica.model';

@Injectable({
  providedIn: 'root'
})
export class InvitadosService {



  private baseUrl:string;

  constructor(private _httpClient: HttpClient, private router :Router) {
    this.baseUrl = environment.urlAPI;
  }

  getInvitados(){
    return this._httpClient.get<Respuesta<Invitado[]>>(this.baseUrl+'/invitados');
  }

  getInvitadosSinInvitacion(){
    return this._httpClient.get<Respuesta<Invitado[]>>(this.baseUrl+'/invitadosSinInvitacion');
  }

  actualizarInvitado(invitado: Invitado) {

    return this._httpClient.put<Respuesta<Invitado>>(this.baseUrl+'/invitados/actualizarInvitado',invitado);
  
  }
  crearInvitado(invitado: Invitado) {

    return this._httpClient.put<Respuesta<Invitado>>(this.baseUrl+'/invitados/crearInvitado',invitado);
  
  }

  eliminarInvitado(idInvitado: number) {
    const options = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
      }),
      body: {
          idInvitado: idInvitado
      },
  };
    return this._httpClient.delete<Respuesta<Invitado>>(this.baseUrl+'/invitados/eliminar',options);
  
  }

  getInvitadosEstadisticas(parametros: Parametros){
    return this._httpClient.get<Respuesta<InvitadoEstadistica[]>>(`${this.baseUrl}/invitadosEstadistica/${parametros.de}/${parametros.tipo}/${parametros.filtro}`);
  }

  getConfirmacionesInvitadosEstadisticas(parametros: Parametros){
    return this._httpClient.get<Respuesta<InvitadoEstadistica[]>>(`${this.baseUrl}/confirmacionesEstadistica/${parametros.de}/${parametros.tipo}/${parametros.filtro}`);
  }

}
