import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Respuesta } from '../models/respuesta.model';
import { Invitado } from '../models/invitado.model';
import { Invitacion } from '../models/invitacion.model';
import { Estadisticas } from '../models/estadisticas.model';

@Injectable({
  providedIn: 'root'
})
export class InvitacionesService {
  private baseUrl:string;

  constructor(private _httpClient: HttpClient, private router :Router) {
    this.baseUrl = environment.urlAPI;
  }

  getInvitacionesInvitados(){
    return this._httpClient.get<Respuesta<Invitacion[]>>(this.baseUrl+'/invitacion/getAllInvitacionesInvitados');
  }

  getAll(){
    return this._httpClient.get<Respuesta<Invitacion[]>>(this.baseUrl+'/invitacion/getAll');
  }

  actualizarInvitacion(invitacion:Invitacion){
    
    return this._httpClient.put<Respuesta<Invitado>>(this.baseUrl+'/invitacion/actualizarInvitacion',invitacion);
  }

  agregarInvitacion(invitacion:Invitacion){
    
    return this._httpClient.post<Respuesta<Invitado>>(this.baseUrl+'/invitacion/addInvitacion',invitacion);
  }

  agregarInvitado(invitacion:String,idInvitado:number){
    const body = [invitacion, idInvitado];
    return this._httpClient.post<Respuesta<Invitado>>(this.baseUrl+'/invitacion/addInvitadoInvitacion',body);
  }

  eliminarInvitadoInvitacion(invitacion:String,idInvitado:number){
    const options = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
      }),
      body: {
          invitacion: invitacion,
          idInvitado: idInvitado
      },
  };
    
    return this._httpClient.delete<Respuesta<Invitado>>(this.baseUrl+'/invitacion/deleteInvitadoInvitacion',options);

  }

  cargarExcelInvitaciones(file: File){
    const formData = new FormData();
    formData.append('file', file);
    return this._httpClient.post<Respuesta<Invitacion[]>>(this.baseUrl+'/invitacion/cargarExcelInvitaciones',formData);
  }

  getEstadisticas(){
    return this._httpClient.get<Respuesta<Estadisticas>>(this.baseUrl+'/estadisticas/getEstadisticas');
  }
}
