import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Respuesta } from '../models/respuesta.model';
import { Usuario } from '../models/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {



  private baseUrl:string;

  constructor(private _httpClient: HttpClient, private router :Router) {
    this.baseUrl = environment.urlAPI;
  }

  getUsuarios(){
    return this._httpClient.get<Respuesta<Usuario[]>>(this.baseUrl+'/usuario/getAll');
  }


  actualizarUsuario(usuario: Usuario) {

    return this._httpClient.put<Respuesta<Usuario>>(this.baseUrl+'/usuario/update',usuario);
  
  }
  crearUsuario(usuario: Usuario) {

    return this._httpClient.post<Respuesta<Usuario>>(this.baseUrl+'/usuario/create',usuario);
  
  }

}
