import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Usuario } from '../models/usuario.model';
import { UsuariosService } from '../_services/usuarios.service';
import { Respuesta } from '../models/respuesta.model';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent {

  constructor(private fb: FormBuilder, private usuarioService:UsuariosService){
    usuarioService.getUsuarios().subscribe((respuesta: Respuesta<Usuario[]>) => {
      if(respuesta.responseCode == "200"){
        this.usuarios = respuesta.objetoRespuesta;       
      }
      
    });
  }
  userSelected: Usuario = {} as Usuario;
  isEditing: boolean = false
  usuarios : Usuario[] = []	;
  formUsuario = this.fb.group({
    id: [0],
    username: ['', [Validators.required]],
    password: ['', [Validators.required]]
  });


  selectUser(user: Usuario) {
    if(Object.keys(this.userSelected).length === 0) {
      this.userSelected = user;
      this.isEditing = true
  
      this.formUsuario.patchValue({
        id: user.id,
        username: user.username,
        password: user.password
      })
    }
  }

  update() {
     
     if(!this.isEditing) {
       this.usuarios[0] = {
       //  id: this.generateId(),
         id: 0,
         username: this.formUsuario.value.username!,
         password: this.formUsuario.value.password!,
       }
       console.log("usuarios por crear",this.usuarios[0]);
       this.usuarioService.crearUsuario(this.usuarios[0]).subscribe(
         (response) => {
           console.log('usuarios actualizado', response);
         },
         (error) => {
           console.log('Error al actualizar el usuario', error);
         }
       );
     }   
     else {
       let index = this.usuarios.map(u => u.id).indexOf(this.userSelected.id);
   
       this.usuarios[index] = {
         //id: this.userSelected.id,
         id: this.userSelected.id,
         username: this.formUsuario.value.username!,
         password: this.formUsuario.value.password!,
       };
       
       console.log("usuario actualizado",this.usuarios[index]);
       this.usuarioService.actualizarUsuario(this.usuarios[index]).subscribe(
         (response) => {
           console.log('usuario actualizado', response);
         },
         (error) => {
           console.log('Error al actualizar el usuario', error);
         }
       );
     }
   
     // clean up
     this.userSelected = {} as Usuario;
     this.isEditing = false 
     this.formUsuario.reset();  
     
   }


   addUsuario() {
    this.usuarios.unshift({
      id: 0,
      username: '',
      password: '',
      
    })
    this.userSelected = this.usuarios[0];
  }

  cancel() {
    if(!this.isEditing && confirm('All unsaved changes will be removed. Are you sure you want to cancel?')) {
          // removes the user that was added
      this.usuarios.splice(0, 1);
    }
  
    this.userSelected = {} as Usuario;
    this.isEditing = false
    this.formUsuario.reset();
  }
}
