import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { InvitacionesService } from '../_services/invitaciones.service';
import { Invitacion } from '../models/invitacion.model';
import { Respuesta } from '../models/respuesta.model';
import { InvitadosService } from '../_services/invitados.service';
import { Invitado } from '../models/invitado.model';
import { FormsModule } from '@angular/forms';
import { ModalContentComponent } from '../modal-content/modal-content.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GrupoInvitacion } from '../models/grupo.invitacion.model';

@Component({
  selector: 'app-invitaciones',
  templateUrl: './invitaciones.component.html',
  styleUrls: ['./invitaciones.component.scss']
})
export class InvitacionesComponent {

filtroRecepcion(filtro:string) {
  if(filtro == ""){
    this.crearGrupos(this.invitacionesTodas);
  }else if(filtro == "S"){
    let invitacionesFiltradas = this.invitacionesTodas.filter(invitacion => invitacion.recepcion == 1);
    this.crearGrupos(invitacionesFiltradas);
  }else if(filtro == "N"){
    let invitacionesFiltradas = this.invitacionesTodas.filter(invitacion => invitacion.recepcion == 0);
    this.crearGrupos(invitacionesFiltradas);
  }
  //this.crearGrupos(this.grupoFiltrado);
}

filtroEnviadas(filtro:string) {
  if(filtro == ""){
    this.crearGrupos(this.invitacionesTodas);
  }else if(filtro == "S"){
    let invitacionesFiltradas = this.invitacionesTodas.filter(invitacion => invitacion.invitacionEnviada == 1);
    this.crearGrupos(invitacionesFiltradas);
  }else if(filtro == "N"){
    let invitacionesFiltradas = this.invitacionesTodas.filter(invitacion => invitacion.invitacionEnviada == 0);
    this.crearGrupos(invitacionesFiltradas);
  }

}

filtroDe(filtro:string) {
  if(filtro == ""){
    this.crearGrupos(this.invitacionesTodas);
  }else if(filtro == "H"){
    let invitacionesFiltradas = this.invitacionesTodas.filter(invitacion => invitacion.de == 'H');
    this.crearGrupos(invitacionesFiltradas);
  }else if(filtro == "J"){
    let invitacionesFiltradas = this.invitacionesTodas.filter(invitacion => invitacion.de == 'J');
    this.crearGrupos(invitacionesFiltradas);
  }else if(filtro == "N"){
    let invitacionesFiltradas = this.invitacionesTodas.filter(invitacion => invitacion.de == null || invitacion.de == '');
    this.crearGrupos(invitacionesFiltradas);
  }

}

  handleUploadComplete(respuesta: Respuesta<Invitacion[]>) {
    console.log("respuesta",respuesta);
  }
cargarInvitaciones() {
  const modalRef = this.modalService.open(ModalContentComponent);
  modalRef.result.then((result) => {
    this.handleUploadComplete(result);
  }).catch((error) => {
    console.log(error);
  });

}
cerrarSuccess() {
  this.existeExito = false;
  this.mensajeExito = "";
}
mensajeExito: string = "";
existeExito: boolean = false;
cerrarAlerta() {
  this.existeError = false;
  this.mensajeError = "";
}


//  invitaciones : Invitacion[] = []	;

  grupoDeinvitaciones : GrupoInvitacion[] = []	;
  grupoFiltrado : GrupoInvitacion[] = []	;

  invitacionesTodas : Invitacion[] = []	;

  invitadosSinInvitacion : Invitado[] = []	;
  invitadoSeleccionado: Invitado = {} as Invitado;
  grupoSeleccionado: number = 0;
  indiceInvitacionSeleccionada: number = 0;
  invitadoAgregar: Invitado = {} as Invitado;
  mensajeError: string = "";
  existeError: boolean = false;

  constructor( private fb: FormBuilder,private invitacionesService :InvitacionesService
      , private invitadosService :InvitadosService, private modalService: NgbModal) { 
    invitacionesService.getInvitacionesInvitados().subscribe((respuesta: Respuesta<Invitacion[]>) => {
      if(respuesta.responseCode == "200"){
        this.invitacionesTodas = respuesta.objetoRespuesta;
        this.crearGrupos(respuesta.objetoRespuesta);            
      }
      
    });
    //Debo consultar los invitados que todavía no han sido agregados a una invitacion
    invitadosService.getInvitadosSinInvitacion().subscribe((respuesta: Respuesta<Invitado[]>) => {
      if(respuesta.responseCode == "200"){
        this.invitadosSinInvitacion = respuesta.objetoRespuesta;
        console.log("Lista de invitados sin invitacion> ",respuesta.objetoRespuesta);  
        this.invitadosSinInvitacion = this.invitadosSinInvitacion.map(invitado => ({
          ...invitado,
          nombreCompleto: `${invitado.nombre} ${invitado.apellidos}`
        }));  
      }
      
    });
}

crearGrupos(invitacionesRespuesta:Invitacion[]){
  console.log("Lista de invitaciones> ",invitacionesRespuesta); 
  let invitaciones = invitacionesRespuesta;
  this.grupoFiltrado = [];
  for (let i = 0; i <  invitaciones.length; i += 5) { 
    this.grupoFiltrado.push({Invitacion: invitaciones.slice(i, i + 5)});
  }
  console.log("Grupo de invitaciones> ",this.grupoDeinvitaciones);   
}
openWhatsApp(numero:string,codigoInvitacion:string) {
  const nombre = this.buscarNombre(numero,codigoInvitacion);
  const mensaje = "Hola "+nombre+"!!! Hoy paso por aquí a saludar y vengo con muy buenas noticias, quería extenderle la invitación a mi boda!! Aquí le dejo todos los detalles!!! %0A%0A" +
  "¡Está por llegar un día de mucha importancia para nuestras vidas, Nuestra boda, y queremos invitarte a que seas parte de el!  %0A%0A"+
  "Este día tan esperado será el 21 de septiembre de este año a las 10:00 a.m en el audiorio Oasis. %0A%0A"+
  "¡Haz clic en el enlace para ver la invitación y confirmar tu asistencia!  http://josueyhilary.site?codInvitacion="+codigoInvitacion +" %0A%0A"+
  "Tome en cuenta que la invitación es personal, como favor te pedimos no compartir el enlace %0A%0A"+
  "¡No podemos esperar a celebrar juntos! %0A%0A"+
  "Con mucha alegría,%0A%0A"+
  "Hilary y Josue"
  const message = encodeURIComponent('Hola mundo');
  const url = `https://wa.me/${numero}?text=${mensaje}`;

  window.open(url, '_blank');
}


buscarNombre(telefono: string, codigoInvitacion: string): string | null {
  // Recorremos cada grupo de invitaciones
  for (const grupo of this.grupoFiltrado) {
    // Recorremos cada invitación en el grupo
    for (const invitacion of grupo.Invitacion) {
      // Verificamos si el código de invitación coincide
      if (invitacion.codigo === codigoInvitacion) {
        // Recorremos cada invitado en la invitación
        for (const invitado of invitacion.invitados) {
          // Verificamos si el teléfono del invitado coincide
          if (invitado.telefono === telefono) {
            return `${invitado.nombre} `; // Retornamos el nombre completo del invitado
          }
        }
      }
    }
  }
  return ""; // Retornamos null si no encontramos al invitado
}
marcarEnviado(invitacion:Invitacion){
  invitacion.invitacionEnviada = 1;
  this.invitacionesService.actualizarInvitacion(invitacion).subscribe(response => {
    if(response.responseCode == "200"){
      this.mensajeExito="Se actualizó correctamente";
      this.existeExito = true;

    }else{
      this.mensajeError = response.message;
      this.existeError = true;
    }
  });
  

}
actualizarrecepcion(invitacion: Invitacion, event: Event){
console.log("actualizando recepcion",event)
console.log("La invitacion es",invitacion);
  if (event.target) {
    const nuevoValor = (event.target as HTMLInputElement).checked ;
    invitacion.recepcion = nuevoValor?1:0;
    console.log("actualizando recepcion",nuevoValor)
    this.invitacionesService.actualizarInvitacion(invitacion).subscribe(response => {
      if(response.responseCode == "200"){
        this.mensajeExito="Se actualizó correctamente";
        this.existeExito = true;

      }else{
        this.mensajeError = response.message;
        this.existeError = true;
      }
    });
  }
  /*
  this.invitacionesService.actualizarInvitacion(codInv,this.invitadoAgregar.idInvitado).subscribe(response => {
    if(response.responseCode == "200"){
    }
  }*/
}

onSelectInvitado(event: any, z : number , i : number) {
  this.grupoSeleccionado = z;
  this.indiceInvitacionSeleccionada = i;
  this.invitadoAgregar = event;
}

agregarInvitado() {
  
  // Llama a la API para agregar el invitado a la base de datos
  let codInv = this.grupoFiltrado[this.grupoSeleccionado].Invitacion[this.indiceInvitacionSeleccionada].codigo; 
  this.invitacionesService.agregarInvitado(codInv,this.invitadoAgregar.idInvitado).subscribe(response => {
    if(response.responseCode == "200"){
      // Actualiza la lista de invitados de la invitación
      this.grupoFiltrado[this.grupoSeleccionado].Invitacion[this.indiceInvitacionSeleccionada].invitados.push(this.invitadoAgregar);
    // Elimina el invitado de la lista de invitados sin invitación
    this.invitadosSinInvitacion = this.invitadosSinInvitacion.filter(invitado => invitado.idInvitado !== this.invitadoAgregar.idInvitado);
    // Resetea el invitado seleccionado
    this.invitadoSeleccionado = {} as Invitado;
    }
    else {
      this.mensajeError = response.message;
      this.existeError = true;
    }    
  });

  
}

  eliminar(codInvitacion: string, invitado: Invitado) {
    
    this.invitacionesService.eliminarInvitadoInvitacion(codInvitacion,invitado.idInvitado).subscribe(response => {
      this.grupoFiltrado.map(grupo => {
        grupo.Invitacion.map(invitacion => {
          if (invitacion.codigo === codInvitacion) {
            invitacion.invitados = invitacion.invitados.filter(invitadoFilter => invitadoFilter.idInvitado !== invitado.idInvitado);

            const updatedInvitado = { ...invitado, nombreCompleto: `${invitado.nombre} ${invitado.apellidos}` };
            this.invitadosSinInvitacion = [...this.invitadosSinInvitacion, updatedInvitado];
          }
        });
      });
/*
      // Actualiza la lista de invitados de la invitación
    let invitacion = this.invitaciones.find(inv => inv.codigo === codInvitacion);
    if (invitacion) {
      // Elimina el invitado de la lista de invitados de la invitación
        invitacion.invitados = invitacion.invitados.filter(invitadoFilter => invitadoFilter.idInvitado !== invitado.idInvitado);
        this.invitadosSinInvitacion = [...this.invitadosSinInvitacion, invitado];
        
        console.log(this.invitadosSinInvitacion)
      }
      */
    });

  }


}
