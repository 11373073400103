import { Component } from '@angular/core';
import { Invitado } from '../models/invitado.model';
import { ActivatedRoute } from '@angular/router';
import { InvitadosService } from '../_services/invitados.service';
import { Parametros } from '../models/parametros.model';
import { InvitadoEstadistica } from '../models/invitadoEstadistica.model';
import { Respuesta } from '../models/respuesta.model';
@Component({
  selector: 'app-estadisticas',
  templateUrl: './estadisticas.component.html',
  styleUrls: ['./estadisticas.component.scss']
})
export class EstadisticasComponent {

  invitados : InvitadoEstadistica [] = [];

  constructor(private route: ActivatedRoute,private invitadosService:InvitadosService) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const de = params['de'];
      const tipo = params['tipo'];
      const filtro = params['filtro'];
      const parametros: Parametros ={
        de:de,
        tipo:tipo,
        filtro:filtro
      }
      console.log("DE: "+de);
      console.log("TIPO: "+tipo);
      console.log("FILTRO: "+filtro);
      if(tipo=='invitados'){
        this.invitadosService.getInvitadosEstadisticas(parametros).subscribe((respuesta: Respuesta<InvitadoEstadistica[]>) => {
          if(respuesta.responseCode == "200"){
            this.invitados = respuesta.objetoRespuesta;   
            console.log("Las solicitudes",this.invitados)    
          }
          
        });
      }else if(tipo=='confirmacion'){
        this.invitadosService.getConfirmacionesInvitadosEstadisticas(parametros).subscribe((respuesta: Respuesta<InvitadoEstadistica[]>) => {
          if(respuesta.responseCode == "200"){
            this.invitados = respuesta.objetoRespuesta;   
            console.log("Las solicitudes",this.invitados)    
          }
          
      });
      
      }
      
      // Usar los parámetros como filtros
    });
  }

}
