import { Component } from '@angular/core';
import { User } from '../objects/User';
import { FormBuilder, Validators } from '@angular/forms';
import { InvitadosService } from '../_services/invitados.service';
import { Respuesta } from '../models/respuesta.model';
import { Invitado } from '../models/invitado.model';

@Component({
  selector: 'app-invitados',
  templateUrl: './invitados.component.html',
  styleUrls: ['./invitados.component.scss']
})
export class InvitadosComponent {

  userSelected: User = {} as User;
  isEditing: boolean = false
  invitados : Invitado[] = []	;
  invitadosFiltrada : Invitado[] = []	;
  invitadoSelected: Invitado = {} as Invitado;

  constructor( private fb: FormBuilder,private invitadosService :InvitadosService) { 
    invitadosService.getInvitados().subscribe((respuesta: Respuesta<Invitado[]>) => {
      if(respuesta.responseCode == "200"){
        this.invitados = respuesta.objetoRespuesta;
        this.invitadosFiltrada = respuesta.objetoRespuesta;
      }
      
    });
}

form = this.fb.group({
  firstName: ['', [Validators.required]],
  lastName: ['', [Validators.required]],
  email: ['', [Validators.required, Validators.email]]
});
formInvitados = this.fb.group({
  idInvitado: [0, [Validators.required]],
  nombre: ['', [Validators.required]],
  apellidos: ['', [Validators.required]],
  telefono: ['', [Validators.required]]
});

selectUser(user: User) {
  if(Object.keys(this.userSelected).length === 0) {
    this.userSelected = user;
    this.isEditing = true

    this.form.patchValue({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email
    })
  }
}
selectInvitado(invitado: Invitado) {
  if(Object.keys(this.invitadoSelected).length === 0) {
    this.invitadoSelected = invitado;
    this.isEditing = true

    this.formInvitados.patchValue({
      idInvitado: invitado.idInvitado,
      nombre: invitado.nombre,
      apellidos: invitado.apellidos,
      telefono: invitado.telefono
    })
  }
}

updateInvitado() {
 // console.log("invitado",invitado);
  
  if(!this.isEditing) {
    this.invitados[0] = {
    //  id: this.generateId(),
      idInvitado: 0,
      nombre: this.formInvitados.value.nombre!,
      apellidos: this.formInvitados.value.apellidos!,
      telefono: this.formInvitados.value.telefono!,
      invitacionEnviada: 0,
      vaRecepcion: false,
      vaCeremonia: false
    }
    console.log("invitado por crear",this.invitados[0]);
    this.invitadosService.crearInvitado(this.invitados[0]).subscribe(
      (response) => {
        console.log('Invitado actualizado', response);
      },
      (error) => {
        console.log('Error al actualizar el invitado', error);
      }
    );
  }   
  else {
    let index = this.invitados.map(u => u.idInvitado).indexOf(this.invitadoSelected.idInvitado);

    this.invitados[index] = {
      //id: this.userSelected.id,
      idInvitado: this.invitadoSelected.idInvitado,
      nombre: this.formInvitados.value.nombre!,
      apellidos: this.formInvitados.value.apellidos!,
      telefono: this.formInvitados.value.telefono!,
      invitacionEnviada: this.invitadoSelected.invitacionEnviada,
      vaRecepcion: this.invitadoSelected.vaRecepcion,
      vaCeremonia: this.invitadoSelected.vaCeremonia
    };
    
    console.log("invitado actualizado",this.invitados[index]);
    this.invitadosService.actualizarInvitado(this.invitados[index]).subscribe(
      (response) => {
        console.log('Invitado actualizado', response);
        this.invitadosFiltrada=this.invitados;
      },
      (error) => {
        console.log('Error al actualizar el invitado', error);
      }
    );
  }

  // clean up
  this.invitadoSelected = {} as Invitado;
  this.isEditing = false 
  this.formInvitados.reset();  
  
}

cancelInvitado() {
  if(!this.isEditing && confirm('All unsaved changes will be removed. Are you sure you want to cancel?')) {
        // removes the user that was added
    this.invitados.splice(0, 1);
  }

  this.invitadoSelected = {} as Invitado;
  this.isEditing = false
  this.formInvitados.reset();
  this.invitadosFiltrada=this.invitados;

}

generateId() {
  return (Math.random() + 1).toString(36).substring(4) + (Math.random() + 1).toString(36).substring(4) 
}
deleteUser(index: number) {
  if(confirm('Are you sure you want to delete this user?')) {
    
    this.invitadosService.eliminarInvitado(this.invitados[index].idInvitado).subscribe(
      (response) => {
        console.log('Invitado eliminado', response);
        this.invitados.splice(index, 1);
        this.invitadoSelected = {} as Invitado;
        this.isEditing = false
        this.formInvitados.reset();
      },
      (error) => {
        console.log('Error al eliminar el invitado', error);
      }
    );
  }
}

addInvitado() {
  this.invitados.unshift({
    idInvitado: 0,
    nombre: '',
    apellidos: '',
    telefono: '',
    invitacionEnviada: 0,
    vaRecepcion: false,
    vaCeremonia: false
  })
  this.invitadoSelected = this.invitados[0];
}

onInput(event: Event,id:number) {
  const input = event.target as HTMLInputElement;
  console.log('Input changed:', input.value);

  this.invitadosFiltrada = this.invitados.filter((invitado) => {
    return invitado.idInvitado == id || invitado.nombre.toLowerCase().includes(input.value.toLowerCase());
  });
}

}

