<!-- Bootstrap CSS -->
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css">

<!-- Bootstrap Icons CSS --> 
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css">

  
  <div class="row justify-content-center">
    <div class="container">
        <form [formGroup]="formUsuario" (ngSubmit)="update()">
        <table class="table">
            <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Usuario</th>
              <th scope="col">Contraseña</th>
              
              <th>
                <button
                    type="button"
                    title="Add New"
                    class="btn btn-primary"
                    (click)="addUsuario()"
                    >
                    <i class="bi bi-plus"></i>
                </button>

                

              </th>
            </tr>
          </thead>
    
          <tbody>
            <ng-container *ngFor="let usuario of usuarios; let i = index">
              <tr
                      *ngIf="userSelected != usuario"
                      (click)="selectUser(usuario)"
                  >
                <td>{{ usuario.id }}</td>
                <td>{{ usuario.username }}</td>
                <td>{{ usuario.password }}</td>
                
                <td>
                  
                </td>
              </tr>
          
                  <tr *ngIf="userSelected == usuario" class="table-primary">
                <td>{{ usuario.id }}</td>
                <td>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="username"
                      [value]="usuario.username"
                      title="Nombre"
                      
                      />
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="password"
                    [value]="usuario.password"
                    title="Password"
                    />
                </td>

                <td>             
                  <button
                      class="btn btn-primary btn-sm mx-1"
                      title="update"
                      type="button"
                      (click)="update()"
                    >
                      <i class="bi bi-lightning"></i>
                    </button>
          
                    <button
                        class="btn btn-secondary btn-sm mx-1"
                        title="Cancel Changes"
                        type="button"
                            (click)="cancel()"
                        >
                            <i class="bi bi-x-lg"></i>
                        </button>
                        
                  </td>


                  <!-- Delete User button, app.component.html -->
                
              </tr>
            </ng-container>
          </tbody>
          
          
        </table>
    </form>
    </div>
    
  </div>
