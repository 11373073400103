<div class="app" style="display: flex; height: 100vh">
  
    <app-navbar></app-navbar>
    <div class="container">

        <app-login *ngIf="!isLoggedIn()" class="h-100"></app-login>

        <router-outlet></router-outlet>
  </div>
</div>

