<!-- Bootstrap CSS -->
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css">

<!-- Bootstrap Icons CSS --> 
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css">

  
  <div class="row justify-content-center">
    <div class="container">
        <form [formGroup]="formInvitados" (ngSubmit)="updateInvitado()">
        <table class="table">
            <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Nombre</th>
              <th scope="col">Apellidos</th>
              <th scope="col">telefono</th>
              <th scope="col">Inv Enviada</th>
              <th scope="col">Ceremonia</th>
              <th scope="col">Recepcion</th>
              <th>
                <button
                    type="button"
                    title="Add New"
                    class="btn btn-primary"
                    (click)="addInvitado()"
                    >
                    <i class="bi bi-plus"></i>
                </button>

                

              </th>
            </tr>
          </thead>
    
          <tbody>
            <ng-container *ngFor="let invitado of invitadosFiltrada; let i = index">
              <tr
                      *ngIf="invitadoSelected != invitado"
                      (click)="selectInvitado(invitado)"
                  >
                <td>{{ invitado.idInvitado }}</td>
                <td>{{ invitado.nombre }}</td>
                <td>{{ invitado.apellidos }}</td>
                <td>{{ invitado.telefono }}</td>
                <td>{{ invitado.invitacionEnviada }}</td>
                <td>{{ invitado.vaCeremonia }}</td>
                <td>{{ invitado.vaRecepcion }}</td>
                <td>
                  
                </td>
              </tr>
          
                  <tr *ngIf="invitadoSelected == invitado" class="table-primary">
                <td>{{ invitado.idInvitado }}</td>
                <td>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="nombre"
                      [value]="invitado.nombre"
                      title="Nombre"
                      (input)="onInput($event,invitado.idInvitado)"

                      />
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="apellidos"
                    [value]="invitado.apellidos"
                    title="Apellidos"
                    
                    />
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="telefono"
                    [value]="invitado.telefono"
                    title="Telefono"
                    />
                </td>
                <td>
                    {{ invitado.invitacionEnviada }}
                    
                </td>
                <td>
                    {{ invitado.vaRecepcion }}
                    
                </td>

                <td>
                    {{ invitado.vaCeremonia }}
                    
                </td>

                <td>             
                  <button
                      class="btn btn-primary btn-sm mx-1"
                      title="update"
                      type="button"
                      (click)="updateInvitado()"
                    >
                      <i class="bi bi-lightning"></i>
                    </button>
          
                    <button
                        class="btn btn-secondary btn-sm mx-1"
                        title="Cancel Changes"
                        type="button"
                            (click)="cancelInvitado()"
                        >
                            <i class="bi bi-x-lg"></i>
                        </button>
                        <button
                        class="btn btn-danger btn-sm mx-1"
                        title="delete"
                        type="button"
                        (click)="deleteUser(i)"
                >
                    <i class="bi bi-trash"></i>
            </button>
                  </td>


                  <!-- Delete User button, app.component.html -->
                
              </tr>
            </ng-container>
          </tbody>
          
          
        </table>
    </form>
    </div>
    
  </div>
