import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Respuesta } from '../models/respuesta.model';
import { Solicitud } from '../models/solicitud.model';

@Injectable({
  providedIn: 'root'
})
export class SolicitudesService {



  private baseUrl:string;

  constructor(private _httpClient: HttpClient, private router :Router) {
    this.baseUrl = environment.urlAPI;
  }

  getSolicitudes(){
    return this._httpClient.get<Respuesta<Solicitud[]>>(this.baseUrl+'/solicitud');
  }

  setResuelta(solicitud : Solicitud){
    return this._httpClient.put<Respuesta<Solicitud[]>>(this.baseUrl+'/resolverSolicitud',solicitud);
  }
 

}
