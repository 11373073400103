
    <CDBSidebar textColor="#ffffff" backgroundColor="#333333">
        <div *ngIf="isLoggedIn()">
        <CDBSidebarHeader [prefix]="icon" >
            <ng-template #icon>
                <i class="fa fa-bars fa-large"></i>
            </ng-template>
            Admin
        </CDBSidebarHeader>

        <CDBSidebarContent>
            <CDBSidebarMenu class="sidebar-content">
                <CDBSidebarMenuItem link='/home' icon="user" >
                    Home
                </CDBSidebarMenuItem>
                <CDBSidebarMenuItem link='/invitados' icon="columns" >
                    Invitados
                </CDBSidebarMenuItem>
                <CDBSidebarMenuItem link='/invitaciones' icon="table" >
                    Invitaciones
                </CDBSidebarMenuItem>
                <CDBSidebarMenuItem link='/usuarios' icon="user" >
                    Usuarios
                </CDBSidebarMenuItem>
                <CDBSidebarMenuItem link='/solicitudes' icon="user" >
                    Solicitudes
                </CDBSidebarMenuItem>
                
                
            </CDBSidebarMenu>
        </CDBSidebarContent>
        <div class="mt-auto">

            <CDBSidebarFooter style="text-align: center; margin-top: auto;">
                <div class="sidebar-btn-wrapper" style="padding: 20px 5px">

                    <button (click)="logout()"  >Logout</button>

                </div>
            </CDBSidebarFooter>
        </div>
    </div>
    </CDBSidebar>
