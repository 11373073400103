import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { InvitadosComponent } from './invitados/invitados.component';
import { InvitacionesComponent } from './invitaciones/invitaciones.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { ListaInvitacionesComponent } from './lista-invitaciones/lista-invitaciones.component';
import { SolicitudesComponent } from './solicitudes/solicitudes.component';
import { EstadisticasComponent } from './estadisticas/estadisticas.component';

const routes: Routes = [

  { path: 'login', component: LoginComponent}, 
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] }, 
  { path: 'invitados', component: InvitadosComponent, canActivate: [AuthGuard] }, 
  { path: 'invitaciones', component: InvitacionesComponent, canActivate: [AuthGuard] } ,
  { path: 'usuarios', component: UsuariosComponent, canActivate: [AuthGuard] },
  { path: 'listaInvitaciones', component: ListaInvitacionesComponent, canActivate: [AuthGuard] } ,
  { path: 'solicitudes', component: SolicitudesComponent, canActivate: [AuthGuard] },
  { path: 'estadisticas', component: EstadisticasComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
