import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { HttpRequestInterceptor } from './_helper/http.interceptor';
import { ReactiveFormsModule } from '@angular/forms';
import { InvitadosComponent } from './invitados/invitados.component';
import { InvitacionesComponent } from './invitaciones/invitaciones.component';
import { NavbarComponent } from './navbar/navbar.component';
import { AuthGuard } from './auth.guard';
import { HomeComponent } from './home/home.component';
import { SidebarModule } from 'ng-cdbangular';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { ModalContentComponent } from './modal-content/modal-content.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { ListaInvitacionesComponent } from './lista-invitaciones/lista-invitaciones.component';
import { SolicitudesComponent } from './solicitudes/solicitudes.component';
import { EstadisticasComponent } from './estadisticas/estadisticas.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    InvitadosComponent,
    InvitacionesComponent,
    NavbarComponent,
    HomeComponent,
    ModalContentComponent,
    UsuariosComponent,
    ListaInvitacionesComponent,
    SolicitudesComponent,
    EstadisticasComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule.forRoot([]),
    SidebarModule,
    BrowserAnimationsModule,
    NgSelectModule,
    FormsModule
    ],
  providers: [AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
