import { Component } from '@angular/core';
import { Respuesta } from '../models/respuesta.model';
import { FormBuilder, Validators } from '@angular/forms';
import { SolicitudesService } from '../_services/solicitud.service';
import { Solicitud } from '../models/solicitud.model';

@Component({
  selector: 'app-solicitudes',
  templateUrl: './solicitudes.component.html',
  styleUrls: ['./solicitudes.component.scss']
})
export class SolicitudesComponent {

  solicitudSelected:Solicitud = {} as Solicitud;
  isEditing: boolean = false;
  solicitud : Solicitud[] = []	;
  solicitudes : Solicitud[] = []	;

  formSolicitud = this.fb.group({
    id: [0, [Validators.required]],
    nombre: ['', [Validators.required]],
    telefono: ['', [Validators.required]],
    cantidad: [0, [Validators.required]],
    comentarios: ['', [Validators.required]],
    resuelta: [false, [Validators.required]],
  });

  constructor(private fb: FormBuilder, private solicitudesService:SolicitudesService){
    solicitudesService.getSolicitudes().subscribe((respuesta: Respuesta<Solicitud[]>) => {
      if(respuesta.responseCode == "200"){
        this.solicitudes = respuesta.objetoRespuesta;   
        console.log("Las solicitudes",this.solicitudes)    
      }
      
    });
  }

  update() {
     
    if(!this.isEditing) {
    }   
    else {
      let index = this.solicitudes.map(u => u.id).indexOf(this.solicitudSelected.id);
      // console.log("Form de",this.formSolicitud.value.de);

      this.solicitudes[index] = {
        //id: this.userSelected.id,
        ...this.solicitudSelected,
        resuelta: this.formSolicitud.value.resuelta!,
        
      };
      
      console.log("La solicitud que se va a actualizar es: ",this.solicitudes[index])
      this.solicitudesService.setResuelta(this.solicitudes[index]).subscribe(
        (response) => {
          console.log('solicitud actualizado', response);
        },
        (error) => {
          console.log('Error al actualizar la solicitud', error);
        }
      );
    }
  
    // clean up
    this.solicitudSelected = {} as Solicitud;
    this.isEditing = false 
    this.formSolicitud.reset();  
    
  }

  selectSolicitud(solicitud: Solicitud) {
    console.log("invitacion seleccionada",solicitud)
    if(Object.keys(this.solicitudSelected).length === 0) {
      this.solicitudSelected = solicitud;
      this.isEditing = true
  
      this.formSolicitud.patchValue({
        id: solicitud.id,
        nombre: solicitud.nombre,
        cantidad: solicitud.cantidad,
        resuelta: solicitud.resuelta,
        comentarios: solicitud.comentarios,

      })
    }
  }
  cancel() {
    if(!this.isEditing && confirm('All unsaved changes will be removed. Are you sure you want to cancel?')) {
          // removes the user that was added
      this.solicitudes.splice(0, 1);
    }
  
    this.solicitudSelected = {} as Solicitud;
    this.isEditing = false
    this.formSolicitud.reset();
  }
}
