import { Component } from '@angular/core';
import { StorageService } from './_services/storage.service';
import { AuthService } from './_services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'admin-boda';
  username?: string;

  constructor(
    private storageService: StorageService,
    private authService: AuthService/*,
    private eventBusService: EventBusService*/
  ) {
    //this.isLoggedIn = this.authService.isAuthenticated();

  }

   login(username: string, password: string){
   
  }
  isLoggedIn() {
    return this.authService.isAuthenticated();
  }
 
}
