import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn: boolean = false;

  constructor(private http: HttpClient, private router :Router) { }

  login(username: string, password: string): Observable<boolean> {
    console.log("LOGIN:::"+username + "-"+password);
    console.log("HACER EL LLAMADO A:::"+`${environment.urlAPI}`)

    return this.http.post<any>(`${environment.urlAPI}/login`, {username, password})
    .pipe(
      map(response => {
        console.log("Hay respuesta del servidor");
        console.log(response);
        localStorage.setItem('JWT_Token', response.accessToken);

        this.isLoggedIn = true;
        return true;
      }),
      catchError(error => {
        console.log("La respuesta fue error");
        console.log(error);
        this.isLoggedIn = false;
        return of(false);
      })
    );
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem('jwt', jwt);
  }

  logout(): void {
    localStorage.removeItem('JWT_Token');
    this.router.navigateByUrl('/');
    this.isLoggedIn = false;
  }

  isAuthenticated(): boolean {
    const token = localStorage.getItem('JWT_Token');
    return !!token;
  }


}
